import type { Reported, Summary } from '../context/Devices.js'
import { FakeDevice } from './FakeDevice.js'

export const FakeTracker = () => (
	<FakeDevice fakeState={fakeTrackerState} fakeHistory={fakeTrackerHistory} />
)

const fakeTrackerState = (): Reported => ({
	cfg: {
		act: true,
		loct: 300,
		actwt: 120,
		mvres: 120,
		mvt: 3600,
		accath: 4,
		accith: 4,
		accito: 60,
		nod: [],
	},
	btn: { v: 1, ts: Date.now() },
	dev: {
		v: {
			imei: '351358811471140',
			iccid: '89470060200703359994',
			modV: 'mfw_nrf9160_1.3.2',
			brdV: 'thingy91_nrf9160',
			appV: '1.7.0-thingy91+debug+memfault',
			bat: 'LP502540',
		},
		ts: Date.now(),
	},
	roam: {
		v: {
			band: 20,
			nw: 'LTE-M',
			rsrp: -95,
			area: 30401,
			mccmnc: 24201,
			cell: 21679616,
			ip: '10.104.69.116',
			eest: 7,
		},
		ts: Date.now(),
	},
	env: {
		v: {
			temp: 21.855308532714844,
			hum: 13.395695686340332,
			atmp: 99.776,
			bsec_iaq: 29,
		},
		ts: Date.now(),
	},
	fg: {
		v: {
			V: 2754,
			I: -250,
			T: 231,
			SoC: 93,
			TTF: 4652,
		},
		ts: Date.now(),
	},
})
const fakeTrackerHistory = (): Summary => ({
	base: new Date(),
	bat: [
		[2.5, 1],
		[5.5, 61],
		[3.895, 112],
		[3.895, 172],
		[3.895, 232],
		[3.891, 292],
		[3.895, 352],
		[3.895, 412],
		[3.895, 472],
		[3.891, 532],
		[3.895, 592],
		[3.899, 652],
		[3.891, 712],
		[3.891, 772],
		[3.891, 832],
		[3.895, 892],
		[3.895, 952],
		[3.895, 1012],
		[3.903, 1072],
		[3.903, 1132],
		[3.895, 1192],
		[3.895, 1252],
		[3.891, 1312],
		[3.895, 1372],
		[3.895, 1432],
		[3.895, 1492],
		[3.895, 1552],
		[3.895, 1612],
		[3.891, 1672],
		[3.895, 1732],
		[3.895, 1792],
		[3.895, 1852],
		[3.895, 1912],
		[3.899, 1972],
		[3.895, 2032],
		[3.899, 2092],
		[3.891, 2152],
		[3.895, 2212],
		[3.895, 2272],
		[3.899, 2332],
		[3.895, 2392],
		[3.895, 2452],
		[3.895, 2512],
		[3.895, 2572],
		[3.895, 2632],
		[3.895, 2692],
		[3.899, 2752],
		[3.899, 2812],
		[3.899, 2872],
		[3.895, 2932],
		[3.895, 2992],
		[3.895, 3052],
		[3.895, 3112],
		[3.895, 3172],
		[3.895, 3232],
		[3.895, 3292],
		[3.899, 3352],
		[3.895, 3412],
		[3.895, 3472],
		[3.895, 3532],
		[3.895, 3592],
	],
	temp: [
		[10, 1],
		[25, 61],
		[22.585, 112],
		[22.575, 172],
		[22.575, 232],
		[22.575, 292],
		[22.555, 352],
		[22.565, 412],
		[22.565, 472],
		[22.575, 532],
		[22.565, 592],
		[22.555, 652],
		[22.555, 712],
		[22.545, 772],
		[22.565, 832],
		[22.565, 892],
		[22.565, 952],
		[22.565, 1012],
		[22.565, 1072],
		[22.565, 1132],
		[22.575, 1192],
		[22.555, 1252],
		[22.565, 1312],
		[22.565, 1372],
		[22.575, 1432],
		[22.565, 1492],
		[22.565, 1552],
		[22.555, 1612],
		[22.555, 1672],
		[22.545, 1732],
		[22.545, 1792],
		[22.535, 1852],
		[22.535, 1912],
		[22.535, 1972],
		[22.535, 2032],
		[22.535, 2092],
		[22.535, 2152],
		[22.525, 2212],
		[22.535, 2272],
		[22.535, 2332],
		[22.525, 2392],
		[22.525, 2452],
		[22.535, 2512],
		[22.535, 2572],
		[22.525, 2632],
		[22.535, 2692],
		[22.535, 2752],
		[22.525, 2812],
		[22.505, 2872],
		[22.475, 2932],
		[22.485, 2992],
		[22.485, 3052],
		[22.475, 3112],
		[22.475, 3172],
		[22.475, 3232],
		[22.455, 3292],
		[22.455, 3352],
		[22.455, 3412],
		[22.445, 3472],
		[22.425, 3532],
		[22.435, 3592],
	],
})
